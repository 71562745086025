<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title" help-page-component="UpdateShipment"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <ShipmentAlert
                    :alert="$t('message.closeAlert')"
                    :show="this.updatedShipmentUnsaved"
                    type="warning"
                    class="mt-3"
                >
                </ShipmentAlert>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="shipmentForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <ShipmentGeneralInfo v-if="loader"></ShipmentGeneralInfo>
                                    <template v-if="!loader">
                                        <v-layout row pt-6 v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveContract') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                    :items="allActiveContracts"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Contract.id"
                                                    item-text="Contract.title"
                                                    required
                                                    solo
                                                    @change="loadNewShipment($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                            <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[1,5].includes(Shipment__salestype_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-btn
                                                    small
                                                    v-if="Invoice__id == null"
                                                    @click="addInvoice"
                                                >{{ $t('message.addInvoice') }}
                                                </v-btn>
                                                <span>{{ Invoice__invno }}</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ Customer__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerSpecificRequests') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 :class="Shipment__Customer__doc_setting != null ? 'red--text darken-1' : ''"><div v-html="nl2br(purify(Shipment__Customer__doc_setting))"></div></v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ Supplier__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.documentsSentTrackingNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Shipment__courier"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Shipment__courier = $event"
                                                />
                                                <v-checkbox
                                                        :label="$t('message.na')"
                                                        dense
                                                        class="ml-3 mt-2"
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        :true-value="1"
                                                        v-model="Shipment__awb_not_required"
                                                />
                                            </v-flex>
                                            <v-flex xs6 lg2 md3 py-1 offset-xs-6>
                                                <v-btn block @click="sendCourierInformation()" :loading="loading.sendCourier">
                                                    {{ $t('message.sendCourierInformationToBuyer') }}
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.sendTo') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-select
                                                        :items="courierToAddresses"
                                                        :label="Shipment__courier != null ? $t('message.required') : ''"
                                                        :value="Shipment__courier_to_address"
                                                        :rules="Shipment__courier != null ? [...validationRules.required] : [true]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Shipment__courier_to_address = $event == undefined ? null : $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.documentsSentVia') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-select
                                                        :items="allCouriers"
                                                        :value="Shipment__contract_courier"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Courier.id"
                                                        item-text="Courier.title"
                                                        solo
                                                        @change="Shipment__contract_courier = $event == undefined ? null : $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.documentsSentOn') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-menu
                                                        ref="courierDateMenu"
                                                        v-model="courierDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedCourierDateFormatted"
                                                                append-icon="event"
                                                                autocomplete="new-password"
                                                                class="custom force-text-left"
                                                                clearable
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Shipment__courierdate = null"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Shipment__courierdate"
                                                            @input="courierDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center font-sm" style="font-stretch: extra-condensed">{{ $t('message.supplierDocumentsReceivedTrackingNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Shipment__supplier_doc_courier"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Shipment__supplier_doc_courier = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center font-sm" style="font-stretch: extra-condensed">{{ $t('message.supplierDocumentsSentVia') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-select
                                                        :items="allCouriers"
                                                        :value="Shipment__supplier_courier"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="Courier.id"
                                                        item-text="Courier.title"
                                                        solo
                                                        @change="Shipment__supplier_courier = $event == undefined ? null : $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center font-sm" style="font-stretch: extra-condensed">{{ $t('message.supplierDocumentsReceivedOn') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-menu
                                                        ref="supplierDocDateMenu"
                                                        v-model="supplierDocDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedSupplierDocDateFormatted"
                                                                append-icon="event"
                                                                autocomplete="new-password"
                                                                class="custom force-text-left"
                                                                clearable
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Shipment__supplierdocdate = null"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            @input="supplierDocDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="Shipment__supplierdocdate"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierInvoiceNumber') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                        :value="Shipment__supplier_invoice_no"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Shipment__supplier_invoice_no = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierInvoiceDate') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-menu
                                                        ref="supplierInvoiceDateMenu"
                                                        v-model="supplierInvoiceDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedSupplierInvoiceDateFormatted"
                                                                append-icon="event"
                                                                autocomplete="new-password"
                                                                class="custom force-text-left"
                                                                clearable
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="Shipment__supplier_invoice_date = null"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            @input="supplierInvoiceDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="Shipment__supplier_invoice_date"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.statusDatesCarrier') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <ShipmentStatusInfo v-if="loader"></ShipmentStatusInfo>
                                    <template v-if="!loader">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.osd') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ formatDate(Shipment__shipweekdate) }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.ets') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-menu
                                                        ref="etsDateMenu"
                                                        v-model="etsDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedEtsDateFormatted"
                                                                append-icon="event"
                                                                autocomplete="new-password"
                                                                clearable
                                                                class="custom force-text-left"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                @click:clear="Shipment__etsdate = null"
                                                                v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Shipment__etsdate"
                                                            @input="etsDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-3 class="align-center">{{ $t('message.etd') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-3>
                                                <v-menu
                                                        ref="etdDateMenu"
                                                        v-model="etdDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedEtdDateFormatted"
                                                                append-icon="event"
                                                                autocomplete="new-password"
                                                                clearable
                                                                class="custom force-text-left"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                @click:clear="Shipment__etddate = null"
                                                                v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Shipment__etddate"
                                                            @input="etdDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-3 class="align-center">{{ $t('message.eta') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-3>
                                                <v-menu
                                                        ref="etaDateMenu"
                                                        v-model="etaDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        max-width="290px"
                                                        min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :rules="[...validationRules.formattedDate]"
                                                                :value="computedEtaDateFormatted"
                                                                append-icon="event"
                                                                autocomplete="new-password"
                                                                clearable
                                                                class="custom force-text-left"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                @click:clear="Shipment__etadate = null"
                                                                v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            v-model="Shipment__etadate"
                                                            @input="etaDateMenu = false"
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.shipmentStatus') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-row class="no-gutters">
                                                    <v-col :class="statusHasText ? 'col-5 col-xs-12' : 'col-12'">
                                                        <v-select
                                                                :items=shipmentStatuses
                                                                :value="Shipment__shipmentstatus_id"
                                                                clearable
                                                                dense
                                                                hide-details="auto"
                                                                item-value="Shipmentstatus.id"
                                                                item-text="Shipmentstatus.title"
                                                                solo
                                                                @change="Shipment__shipmentstatus_id = $event == undefined ? null : $event"
                                                        />
                                                    </v-col>
                                                    <v-col :class="statusHasText ? 'col-7 col-xs-12 pl-2' : ''">
                                                        <v-text-field
                                                                :rules="[...validationRules.plainTextWithNumbers]"
                                                                :placeholder="$t('message.typeStatusText')"
                                                                :value="Shipment__shipmentstatus_text"
                                                                autocomplete="new-password"
                                                                class="force-text-left"
                                                                clearable
                                                                dense
                                                                hide-details="auto"
                                                                solo
                                                                v-if="statusHasText"
                                                                @change="Shipment__shipmentstatus_text = $event"
                                                                @click:clear="Shipment__shipmentstatus_text = null"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.remarksForBuyer') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items=allInternalRemarks
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-value="InternalRemark.id"
                                                        item-text="InternalRemark.title"
                                                        solo
                                                        v-model="Shipment__internalremark_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <InternalRemark/>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center"></v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-textarea
                                                        :rules="[...validationRules.paragraph]"
                                                        :value="Shipment__internalremark"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        rows="2"
                                                        solo
                                                        @change="Shipment__internalremark = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.internalReference') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-textarea
                                                        :rules="[...validationRules.paragraph]"
                                                        :value="Shipment__shipremark"
                                                        class="yellow lighten-4"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        rows="3"
                                                        solo
                                                        @change="Shipment__shipremark = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.sailed') }}?</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-checkbox
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        :true-value="1"
                                                        v-model="Shipment__sailing"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.agent') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                        :items="allAgents"
                                                        :value="Shipment__agent_id"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Agent.title"
                                                        item-value="Agent.id"
                                                        solo
                                                        @change="Shipment__agent_id = $event == undefined ? null : $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.agentScac') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Shipment__scac"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Shipment__scac = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.blNo') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                        :rules="[...validationRules.plainTextWithNumbers]"
                                                        :value="Shipment__blno"
                                                        autocomplete="new-password"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Shipment__blno = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.lcInfo') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcNumber') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-text-field
                                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                    :value="Shipment__lcno"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Shipment__lcno = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcReceivedDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    ref="lcReceiveDateMenu"
                                                    v-model="lcReceiveDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :rules="[...validationRules.formattedDate]"
                                                            :value="computedLcReceiveDateFormatted"
                                                            autocomplete="new-password"
                                                            append-icon="event"
                                                            class="custom force-text-left"
                                                            clearable
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Shipment__lcreceivedate = null"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        v-model="Shipment__lcreceivedate"
                                                        @input="lcReceiveDateMenu = false"
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcLatestShipmentDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    ref="lcLatestShipmentDateMenu"
                                                    v-model="lcLatestShipmentDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :rules="[...validationRules.formattedDate]"
                                                            :value="computedLcLatestShipmentDateFormatted"
                                                            autocomplete="new-password"
                                                            append-icon="event"
                                                            class="custom force-text-left"
                                                            clearable
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Shipment__lclsdate = null"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        v-model="Shipment__lclsdate"
                                                        @input="lcLatestShipmentDateMenu = false"
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.lcExpiryDate') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-menu
                                                    ref="lcExpiryDateMenu"
                                                    v-model="lcExpiryDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :rules="[...validationRules.formattedDate]"
                                                            :value="computedLcExpiryDateFormatted"
                                                            append-icon="event"
                                                            autocomplete="new-password"
                                                            class="custom force-text-left"
                                                            clearable
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Shipment__lcedate = null"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        v-model="Shipment__lcedate"
                                                        @input="lcExpiryDateMenu = false"
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.containers') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-6>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.containerCount') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-row class="px-3">
                                                <v-col cols="5" class="pa-0 ma-0">
                                                    <v-text-field
                                                            :rules="[...validationRules.number]"
                                                            :value="Shipment__containercount"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            v-if="[4,7].indexOf(Shipment__containersize_id) == -1"
                                                            @change="Shipment__containercount = $event"
                                                    />
                                                </v-col>
                                                <v-col cols="1" class="pa-0 ma-0 text-center">
                                                    <v-icon v-if="[4,7].indexOf(Shipment__containersize_id) == -1" class="mt-2">mdi-close</v-icon>
                                                </v-col>
                                                <v-col cols="6" class="pa-0 ma-0">
                                                    <v-select
                                                            :items="containerSizes"
                                                            :value="Shipment__containersize_id"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-text="Containersize.title"
                                                            item-value="Containersize.id"
                                                            reverse
                                                            solo
                                                            @change="Shipment__containersize_id = $event == undefined ? null : $event"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-flex>
                                    </v-layout>
                                    <div class="pt-3"></div>
                                    <v-layout row v-for="(item, index) in Containers" :key="item.Container.id">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.container') + ' #' + (index + 1) }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-row class="px-3">
                                                <v-col cols="6" class="pa-0 ma-0">
                                                    <v-text-field
                                                            :placeholder="$t('message.containerNo')"
                                                            :rules="[...validationRules.plainTextWithNumbers]"
                                                            :value="item.Container.title"
                                                            :key="'container_' + index"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            @change="updateContainerRowHandle( item.index, 'Container.title',$event)"
                                                    />
                                                </v-col>
                                                <v-col cols="5" class="py-0 ma-0 pl-3">
                                                    <v-text-field
                                                            :placeholder="$t('message.sealNo')"
                                                            :rules="[...validationRules.plainTextWithNumbers]"
                                                            :value="item.Container.sealno"
                                                            :key="'seal_' + index"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            @change="updateContainerRowHandle( item.index, 'Container.sealno',$event)"
                                                    />
                                                </v-col>
                                                <v-col cols="1" class="pt-1 px-0 ma-0 flex-row pb-0" v-if="Containers.length == index + 1">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon small @click="deleteContainer(index)" v-if="Containers.length != 1" v-on="on">
                                                                <v-icon>mdi-minus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('message.deleteThisContainer') }}</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon small @click="addContainer" v-on="on">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('message.addContainer') }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="1" class="pt-1 px-0 ma-0 flex-row pb-0" v-else>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn icon small @click="deleteContainer(index)" v-on="on">
                                                                <v-icon>mdi-minus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('message.deleteThisContainer') }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.shippingDocuments') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <FileUploadList
                                        :file-name-prefix="fileNamePrefix"
                                        :files="files"
                                        :page-key="fileListPageKey"
                                        :updateMode="false"
                                        module="shipping"
                                        class="pt-3"
                                        @upload-file-done="addUploadedFile"
                                    />
                                    <div class="mt-5">
                                        <v-row layout>
                                            <v-flex xs6 lg3 md4 pl-5 py-1 class="align-center">{{ $t('message.fullSetDocumentsUploaded') }}?</v-flex>
                                            <v-flex xs6 lg3 md4 py-1 class="d-flex flex-row align-center">
                                                <v-checkbox
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    :true-value="Shipment__documents_completed"
                                                    v-model="Shipment__documents_completed"
                                                />
                                                <div class="d-flex flex-column flex-grow-1">
                                                    <v-btn
                                                        block
                                                        small
                                                        @click="sendDocumentsDialog = true"
                                                        v-if="parseInt(Shipment__salestype_id) !== 5 && (buyerDocuments.length + tmsShippingDocuments.length > 0)"
                                                    >
                                                        {{ $t('message.sendDocumentsToBuyer') }}
                                                    </v-btn>
                                                    <v-btn block small @click="sendNotificationFinanceDialog = true" v-if="false" class="mt-3">
                                                        {{ $t('message.sendNotificationToFinance') }}
                                                    </v-btn>
                                                </div>
                                            </v-flex>
                                        </v-row>
                                    </div>
                                    <TmsShippingDocumentsList :contract-id="Shipment__contract_id" v-if="tmsShippingDocuments.length > 0"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.eutrEudrComplianceFiles') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eutrComplianceRequired') }}?</v-flex>
                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                            <v-checkbox
                                                :true-value="1"
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                v-model="Shipment__eutr_compliance_required"
                                                @change="initiateEutrFileUploadList($event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <template v-if="Shipment__eutr_compliance_required == 1">
                                        <v-row layout>
                                            <v-col cols="12" class="pl-3">
                                                <v-progress-circular
                                                    color="primary"
                                                    indeterminate
                                                    size="30"
                                                    width="4"
                                                    v-if="loading.eutrFiles"
                                                />
                                                <EutrFileUploadList
                                                    :commission-contract="Shipment__salestype_id == 2"
                                                    :contract-id="shipmentContractId"
                                                    :file-name-prefix="fileNamePrefix"
                                                    :files="filteredEutrFiles"
                                                    :key="eutrFileListPageKey"
                                                    :updateMode="false"
                                                    module="shipping"
                                                    class="pt-0"
                                                    @upload-file-done="addUploadedEutrFile"
                                                    v-if="!loading.eutrFiles"
                                                />
                                                <EudrDocumentsChecker
                                                    :contract-id="shipmentContractId"
                                                    :contract-no="[Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-')"
                                                    :files="filteredEutrFiles"
                                                    :update-mode="false"
                                                    class="ml-2"
                                                    v-if="false"
                                                />
                                                <div class="mt-5">
                                                    <v-row layout>
                                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.complianceFilesCompleted') }}?</v-flex>
                                                        <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row align-center">
                                                            <v-checkbox
                                                                class="mt-0"
                                                                dense
                                                                hide-details="auto"
                                                                required
                                                                solo
                                                                :true-value="Shipment__eutr_files_complete"
                                                                v-model="Shipment__eutr_files_complete"
                                                            />
                                                            <div class="d-flex flex-column flex-grow-1">
                                                                <v-btn block small @click="openSendComplianceFilesDialog" v-if="buyerDocuments.length > 0 && Shipment__eutr_files_complete == 1">
                                                                    {{ $t('message.sendComplianceFilesToBuyer') }}
                                                                </v-btn>
                                                            </div>
                                                        </v-flex>
                                                    </v-row>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.trackingInformation') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <TrackingInfo/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-card elevation="0"class="pt-3">
                                        <v-tabs
                                                background-color="grey lighten-3"
                                        >
                                            <v-tab key="email" href="#tab-email" @click="updateHistoryKey()"><v-icon small class="pr-3">send</v-icon>{{ $t('message.notifications') }}</v-tab>
                                            <v-tab key="audit_log" href="#tab-audit_log" @click="updateHistoryKey()"><v-icon small class="pr-3">list</v-icon>{{ $t('message.auditLog') }}</v-tab>
                                            <v-tab-item key="email" value="tab-email">
                                                <NotificationsLog asset="shipments" :asset_id="Shipment__contract_id" :key="'nl' + historyKey"></NotificationsLog>
                                            </v-tab-item>
                                            <v-tab-item key="audit_log" value="tab-audit_log">
                                                <DocumentHistory asset="shipments" :asset_id="Shipment__contract_id" :key="'dh' + historyKey"></DocumentHistory>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                        class="ml-3"
                                        small
                                        v-if="false"
                                    >{{ $t('message.printSummary') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.save"
                                        color="info"
                                        small
                                        class="ml-3 info lighten-2"
                                        @click="saveShipment"
                                    >{{ $t('message.saveChanges') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            width="700px"
            v-model="sendDocumentsDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendDocumentsToBuyer') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectDocuments') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.document') }}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.source') }}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.type') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="buyerDocuments.length + tmsShippingDocuments.length > 0">
                                        <tr v-for="(file, index) in buyerDocuments" v-bind:key="index" v-if="file.AssetDocument.customeraccess == 1 && !checkEutrEudrDocument(file.AssetDocument.doctype_id)">
                                            <td class="width-1-pct">
                                                <v-checkbox
                                                    :value="file.AssetDocument.id"
                                                    class="mt-1"
                                                    dense
                                                    hide-details="auto"
                                                    v-model="selectedDocuments"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">
                                                {{ file.AssetDocument.asset_title }}
                                            </td>
                                            <td class="pt-2 pl-0 text-left">
                                                {{ $t('message.appic') }}
                                            </td>
                                            <td class="pt-2 pl-0 text-left">
                                                <span>{{ $t('message.shipping') }}</span>
                                            </td>
                                        </tr>
                                        <tr v-for="(file, index) in tmsShippingDocuments" v-bind:key="index">
                                            <td class="width-1-pct">
                                                <v-checkbox
                                                    :value="file.Document.id"
                                                    class="mt-1"
                                                    dense
                                                    hide-details="auto"
                                                    v-model="selectedTmsDocuments"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">
                                                {{ file.Document.name }}
                                            </td>
                                            <td class="pt-2 pl-0 text-left">
                                                {{ $t('message.tms') }}
                                            </td>
                                            <td class="pt-2 pl-0 text-left"></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan ="2">{{ $t('message.noDocumentsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="buyerDocumentsContacts.length > 0">
                                        <tr v-for="contact in buyerDocumentsContacts" v-bind:key="contact.Contact.id">
                                            <td>
                                                <v-checkbox
                                                        :value="contact.Contact.email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details="auto"
                                                        v-model="selectedBuyerContacts"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                            <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                            <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendDocumentsDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.send"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendDocumentsToBuyer()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                persistent
                transition="dialog-bottom-transition"
                width="500px"
                v-model="sendNotificationFinanceDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendNotificationToFinance') }}</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="financeDocumentContacts.length > 0">
                                        <tr v-for="(email, index) in financeDocumentContacts" v-bind:key="index">
                                            <td class="width-1-pct">
                                                <v-checkbox
                                                        :value="email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details="auto"
                                                        v-model="selectedFinanceContacts"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ email }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="2">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendNotificationFinanceDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.sendFinance"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendNotificationToFinance()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            width="500px"
            v-model="sendEutrDocumentsDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendComplianceFilesToBuyer') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0 pl-3 text-left">{{ $t('message.document') }}</th>
                                            <th class="grey lighten-3 pa-0 pl-3 text-left">{{ $t('message.source') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold pt-2 pl-3 text-left">{{ $t('message.eutrEudrComplianceFiles') }}</td>
                                            <td class="pt-2 pl-3 text-left">{{ $t('message.appic') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="grey lighten-3 pa-0"></th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                        <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="buyerDocumentsContacts.length > 0">
                                    <tr v-for="contact in buyerDocumentsContacts" v-bind:key="contact.Contact.id">
                                        <td>
                                            <v-checkbox
                                                    :value="contact.Contact.email"
                                                    class="mt-1"
                                                    dense
                                                    hide-details="auto"
                                                    v-model="selectedEutrBuyerContacts"
                                            />
                                        </td>
                                        <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                        <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                        <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                        <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                    </tr>
                                    </tbody>
                                    <tbody v-else>
                                    <tr>
                                        <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendEutrDocumentsDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.sendEutr"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendEutrDocumentToBuyer()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SimpleAlert :alert_message="this.dialogs.error_message" v-bind:dialog.sync="dialogs.error"/>
        <MultipleAccess :asset-id="shipmentContractId" event-id="open-shipment" model="Contracts"></MultipleAccess>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {formatDate, getLastDateOfWeek, log, nl2br, purify} from "Helpers/helpers";
    // import FileUploadList from "../../../components/Appic/FileUploadList";
    import router from "../../../router";
    import InternalRemark from "../../../components/Appic/InternalRemark";
    import MultipleAccess from "../../../components/Appic/Alert/MulipleAccess";
    import { api } from "Api";

    const EudrDocumentsChecker = () => import("Components/Appic/EutrEudr/EudrDocumentsChecker.vue");
    const FileUploadList = () => import("Components/Appic/FileUploadList");
    // const InternalRemark = () => import("Components/Appic/InternalRemark");
    const TrackingInfo = () => import("Components/Appic/TrackingInfo");
    const ShipmentAlert = () => import("Components/Appic/ShipmentAlert");
    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const TmsShippingDocumentsList = () => import("Components/Appic/TmsShippingDocumentsList");
    // const FabShipment = () => import("Components/Appic/FabShipment");
    const NotificationsLog = () => import("Components/Appic/NotificationsLog");
    const ShipmentGeneralInfo = () => import("Components/Appic/Loading/ShipmentGeneralInfo");
    const ShipmentStatusInfo = () => import("Components/Appic/Loading/ShipmentStatusInfo");
    const EutrFileUploadList = () => import("Components/Appic/EutrFileUploadList");

    export default {
        name: "UpdateShipment",
        // beforeRouteUpdate (to, from, next) {
        //     this.loadShipmentById(to.params.shipmentContractId)
        //     this.getContainersByShipment(this.shipmentContractId)
        //     next()
        // },
        props: ['shipmentContractId'],
        components: {
            EudrDocumentsChecker,
            ShipmentStatusInfo,
            ShipmentGeneralInfo,
            DocumentHistory,
            // FabShipment,
            EutrFileUploadList,
            FileUploadList,
            InternalRemark,
            MultipleAccess,
            NotificationsLog,
            ShipmentAlert,
            SimpleAlert,
            TmsShippingDocumentsList,
            TrackingInfo,
        },
        title: 'Shipment:',
        data() {
            return {
                multipleAccess: [],
                multipleAccessPolling: null,
                buyerContacts: [],
                buyerDocuments: [],
                courierToAddresses: [
                    {value: 1, text: this.$t('message.companyAddress')},
                    {value: 2, text: this.$t('message.bank')},
                    {value: 3, text: this.$t('message.forwarder')},
                ],
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                Contract__partial_no: null,
                Contract__revision_no: null,
                Contract__title: null,
                Contract__version: null,
                Customer__title: null,
                Customer__doc_setting: null,
                Invoice__id: null,
                Invoice__invno: null,
                courierDateMenu: false,
                etaDateMenu: false,
                etdDateMenu: false,
                etsDateMenu: false,
                eutrFileListPageKey: 0,
                fileListPageKey: 0,
                filteredEutrFiles: [],
                financeDocumentContacts: [],
                historyKey: '',
                lcExpiryDateMenu: false,
                lcLatestShipmentDateMenu: false,
                lcReceiveDateMenu: false,
                loader: false,
                loading: {
                    sendCourier: false,
                    eutrFiles: false,
                    save: false,
                    send: false,
                    sendEutr: false,
                    sendFinance: false
                },
                loadingRelatedContract: false,
                fscDocumentIds: [],
                pefcDocumentIds: [],
                pageKey: Math.floor(Math.random() * 100),
                panel: [0, 1],
                // rules: {
                //     courierToAddress: v => !!v || this.$t('message.required'),
                // },
                selectedBuyerContacts: [],
                selectedDocuments: [],
                selectedEutrBuyerContacts: [],
                selectedFinanceContacts: [],
                selectedTmsDocuments: [],
                sendDocumentsDialog: false,
                sendEutrDocumentsDialog: false,
                sendNotificationFinanceDialog: false,
                Supplier__title: null,
                supplierDocDateMenu: false,
                supplierInvoiceDateMenu: false,
                tmsShippingDocuments: [],
                validForm: false
            }
        },
        computed: {
            ...mapFields('shipment',{
                Shipment__agent_id: 'current.Shipment.agent_id',
                Shipment__awb_not_required: 'current.Shipment.awb_not_required',
                Shipment__blno: 'current.Shipment.blno',
                Shipment__certification: 'current.Shipment.certification',
                Shipment__containercount: 'current.Shipment.containercount',
                Shipment__containersize_id: 'current.Shipment.containersize_id',
                Shipment__contract_id: 'current.Shipment.contract_id',
                Shipment__contract_courier: 'current.Shipment.contract_courier',
                Shipment__courier: 'current.Shipment.courier',
                Shipment__courierdate: 'current.Shipment.courierdate',
                Shipment__courier_to_address: 'current.Shipment.courier_to_address',
                Shipment__destinatione_id: 'current.Shipment.destinatione_id',
                Shipment__documents: 'current.Shipment.documents',
                Shipment__documents_completed: 'current.Shipment.documents_completed',
                Shipment__etadate: 'current.Shipment.etadate',
                Shipment__etddate: 'current.Shipment.etddate',
                Shipment__etsdate: 'current.Shipment.etsdate',
                Shipment__eutr_compliance_required: 'current.Shipment.eutr_compliance_required',
                Shipment__eutr_files_complete: 'current.Shipment.eutr_files_complete',
                Shipment__freetext1: 'current.Shipment.freetext1',
                Shipment__freetext2: 'current.Shipment.freetext2',
                Shipment__freetext3: 'current.Shipment.freetext3',
                Shipment__freetext4: 'current.Shipment.freetext4',
                Shipment__internalremark: 'current.Shipment.internalremark',
                Shipment__internalremark_id: 'current.Shipment.internalremark_id',
                Shipment__lcedate: 'current.Shipment.lcedate',
                Shipment__lclsdate: 'current.Shipment.lclsdate',
                Shipment__lcno: 'current.Shipment.lcno',
                Shipment__lcreceivedate: 'current.Shipment.lcreceivedate',
                Shipment__salestype_id: 'current.Shipment.salestype_id',
                Shipment__sailing: 'current.Shipment.sailing',
                Shipment__scac: 'current.Shipment.scac',
                Shipment__shipmentstatus_id: 'current.Shipment.shipmentstatus_id',
                Shipment__shipmentstatus_text: 'current.Shipment.shipmentstatus_text',
                Shipment__shipremark: 'current.Shipment.shipremark',
                Shipment__shipweekdate: 'current.Shipment.shipweekdate',
                Shipment__supplier_courier: 'current.Shipment.supplier_courier',
                Shipment__supplier_doc_courier: 'current.Shipment.supplier_doc_courier',
                Shipment__supplier_invoice_date: 'current.Shipment.supplier_invoice_date',
                Shipment__supplier_invoice_no: 'current.Shipment.supplier_invoice_no',
                Shipment__supplierdocdate: 'current.Shipment.supplierdocdate',
                Shipment__Customer__company_id: 'current.Shipment.Customer.company_id',
                Shipment__Customer__doc_setting: 'current.Shipment.Customer.doc_setting',
                updatedShipmentUnsaved: 'updatedShipmentUnsaved'
            }),
            ...mapMultiRowFields('shipment',{
                Containers: 'current.Containers'
            }),
            ...mapGetters('agent', {
                allAgents: 'allAgents',
            }),
            ...mapGetters('contract',{
                allActiveContracts: 'allActiveContracts',
                allClosedContracts: 'allClosedContracts',
                getContractById: 'getContractById'
            }),
            ...mapGetters('courier', {
                allCouriers: 'allCouriers'
            }),
            ...mapGetters('document', {
                allDocuments: 'allDocuments',
                allShippingDirectDocuments: 'allShippingDirectDocuments',
                allShippingCommissionDocuments: 'allShippingCommissionDocuments',
                allStandardShippingDocuments: 'allStandardShippingDocuments'
            }),
            ...mapGetters('internalremark', {
                allInternalRemarks: 'allInternalRemarks'
            }),
            ...mapGetters([
                'containerSizes',
                'couriers',
                // 'internalRemarks',
                'shipmentStatuses',
                'validationRules'
            ]),
            // buyerDocuments () {
            //     // return this.Shipment__documents.filter(document => {
            //     //    return document?.AssetDocument?.customeraccess == 1
            //     // })
            //     return []
            // },
            buyerDocumentsContacts () {
                return this.buyerContacts.filter(contact => {
                   //  return contact?.Contact?.otsshipping == 1
                    return contact?.Contact?.otsnotification == 1
                })
            },
            computedCourierDateFormatted () {
                return formatDate(this.Shipment__courierdate)
            },
            computedEtaDateFormatted () {
                return formatDate(this.Shipment__etadate)
            },
            computedEtdDateFormatted () {
                return formatDate(this.Shipment__etddate)
            },
            computedEtsDateFormatted () {
                return formatDate(this.Shipment__etsdate)
            },
            computedLcLatestShipmentDateFormatted () {
                return formatDate(this.Shipment__lclsdate)
            },
            computedLcReceiveDateFormatted () {
                return formatDate(this.Shipment__lcreceivedate)
            },
            computedLcExpiryDateFormatted () {
                return formatDate(this.Shipment__lcedate)
            },
            computedSupplierDocDateFormatted () {
                return formatDate(this.Shipment__supplierdocdate)
            },
            computedSupplierInvoiceDateFormatted () {
                return formatDate(this.Shipment__supplier_invoice_date)
            },
            eutrFiles() {
                let files = [];
                files = this.allDocuments.ShippingEutrDocuments
                return files
            },
            fileNamePrefix() {
                let prefix = ''
                if(this.Contract__title != null && this.Contract__title != ''){
                    prefix = [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-')
                }
                return prefix
            },
            files () {
                let files = [];
                if([1,5].includes(this.Shipment__salestype_id)){
                    // files = this.allShippingDirectDocuments
                    files = this.allDocuments.ShippingDirectDocuments
                } else {
                    // files = this.allShippingCommissionDocuments
                    files = this.allDocuments.ShippingCommissionDocuments
                }
                return files
            },
            formatDate: () => formatDate,
            statusHasText () {
                let shipmentStatus = this.shipmentStatuses.find(status => status.Shipmentstatus.id === this.Shipment__shipmentstatus_id)
                if(shipmentStatus?.Shipmentstatus?.hastext == 1) {
                    return true
                }
                return false
            }
        },
        methods: {
            ...mapActions('agent', {
                getAllAgents: 'getAllAgents',
                resetAllAgents: 'resetAllAgents'
            }),
            ...mapActions('contract',{
                getAllActiveContracts: 'getAllActiveContracts',
                getAllClosedContracts: 'getAllClosedContracts',
                resetAllContracts: 'resetAllContracts'
            }),
            ...mapActions('courier',{
                getAllCouriers: 'getAllCouriers'
            }),
            ...mapActions('document',{
                resetAllDocuments: 'resetAllDocuments',
                getShippingDocumentsById: 'getShippingDocumentsById',
                getAllStandardShippingDocuments: 'getAllStandardShippingDocuments'
            }),
            ...mapActions('internalremark', {
                getAllInternalRemarks: 'getAllInternalRemarks'
            }),
            ...mapActions('runtime',{
                removeOpenedTab: 'removeOpenedTab'
            }),
            ...mapActions('shipment',{
                getContainersByShipment: 'getContainersByShipment',
                getShipmentById: 'getShipmentById',
                getShipmentTracking: 'getShipmentTracking',
                sendCourierNotificationToBuyer: 'sendCourierNotificationToBuyer',
                sendShipmentDocumentsToBuyer: 'sendShipmentDocumentsToBuyer',
                sendComplianceDocumentToBuyer: 'sendComplianceDocumentToBuyer',
                sendDocumentNotificationToFinance: 'sendDocumentNotificationToFinance',
                updateShipment: 'updateShipment'
            }),
            ...mapMutations('shipment',{
                addContainer: 'addContainer',
                deleteContainer: 'deleteContainer',
            }),
            addInvoice (){
                router.push({name: 'add_invoice', params: { contractId : this.Shipment__contract_id}}).catch(err => {})
            },
            addUploadedFile(fileObject){
                if(this.Shipment__documents.length == 0){
                    this.Shipment__documents.push({AssetDocument: fileObject.AssetDocument})
                    this.saveShipment()
                } else {
                    //add/replace file in existing list
                    const doctype_id = fileObject.AssetDocument.doctype_id
                    let documents = []
                    this.Shipment__documents.forEach((item, index)=>{
                        //Add new check
                        if(doctype_id != item.AssetDocument.doctype_id &&  item.AssetDocument.location != fileObject.AssetDocument.location){
                            documents.push(item)
                        }
                    })
                    const newFile = {
                        AssetDocument: fileObject.AssetDocument
                    }
                    documents.push(newFile)
                    this.Shipment__documents = []
                    this.Shipment__documents = documents
                    this.buyerDocuments = []
                    documents.forEach((item) => {
                        this.buyerDocuments.push(item)
                    })
                    this.saveShipment()
                }
                return true
            },
            addUploadedEutrFile(fileObject) {
                if(this.Shipment__documents.length == 0){
                    this.Shipment__documents.push({AssetDocument: fileObject.AssetDocument})
                    this.saveShipment()
                } else {
                    const doctype_id = fileObject.AssetDocument.doctype_id
                    let documents = []
                    this.Shipment__documents.forEach((item, index)=>{
                        if(doctype_id != item.AssetDocument.doctype_id &&  item.AssetDocument.location != fileObject.AssetDocument.location){
                            documents.push(item)
                        }
                    })
                    const newFile = {
                        AssetDocument: fileObject.AssetDocument
                    }
                    documents.push(newFile)
                    this.Shipment__documents = []
                    this.Shipment__documents = documents
                    this.buyerDocuments = []
                    documents.forEach((item) => {
                        this.buyerDocuments.push(item)
                    })
                    this.saveShipment()
                }
                return true
            },
            beforeUnloadHandler() {
                this.removeOpenedTab('shipment_' + this.shipmentContractId)
            },
            checkEutrEudrDocument(docTypeId) {
                const file = this.eutrFiles.find(ef => ef.Document.doctype_id == docTypeId)
                if(file) {
                    if([2002].includes(parseInt(docTypeId))) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            },
            getBuyerContacts () {
                let company_id = this.Shipment__Customer__company_id
                return new Promise((resolve, reject) => {
                    api
                        .get("/companies/" + company_id + "/contacts")
                        .then(response => {
                            this.buyerContacts = response.data.data
                            resolve('done');
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            resolve('done');
                        })
                })
            },
            getFinanceContacts (office) {
                return new Promise((resolve, reject) => {
                    api
                        .get("/contacts/finance/" + office)
                        .then(response => {
                            this.financeDocumentContacts = response.data.data
                            this.financeDocumentContacts.forEach(c => {
                                this.selectedFinanceContacts.push(c)
                            })
                            resolve('done');
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            resolve('done');
                        })
                })
            },
            getFscPefcDocumentIds() {
                return new Promise((resolve, reject) => {
                    api
                        .get("/documents/shipping/standard/fsc-pefc")
                        .then(response => {
                            if(response.data.data.fsc.length > 0) this.fscDocumentIds = response.data.data.fsc
                            if(response.data.data.pefc.length > 0) this.pefcDocumentIds = response.data.data.pefc
                            resolve('done');
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            resolve('done');
                        })
                })
            },
            initiateEutrFileUploadList(val) {
                if(val == 1){
                    this.loading.eutrFiles = true
                    this.getFscPefcDocumentIds()
                        .then(() => {
                            this.eutrFiles.forEach(file => {
                                if(this.Shipment__certification == 'pefc') {
                                    if(!this.fscDocumentIds.includes(file.Document.doctype_id)){
                                        this.filteredEutrFiles.push(file)
                                    }
                                } else if(this.Shipment__certification == 'fsc'){
                                    if(!this.pefcDocumentIds.includes(file.Document.doctype_id)){
                                        this.filteredEutrFiles.push(file)
                                    }
                                } else {
                                    const certificationFiles = [...this.fscDocumentIds, ...this.pefcDocumentIds]
                                    if(!certificationFiles.includes(file.Document.doctype_id)) {
                                        this.filteredEutrFiles.push(file)
                                    }
                                }
                            })
                            this.loading.eutrFiles = false
                        })
                        .catch(() => {
                            this.loading.eutrFiles = false
                            console.log('Could not filter the PEFC/FSC files')
                        })
                } else {
                    this.filteredEutrFiles = []
                }
            },
            loadNewShipment (val) {
                router.push({name: 'update_shipment', params: { shipmentContractId : val}}).catch(err => {})
            },
            loadShipmentById (val) {
                this.loader = true
                this.getShipmentById(val)
                        .then(()=>{
                            //filter EUTR documents for PEFC/FSC
                            if(this.Shipment__eutr_compliance_required){
                                this.getFscPefcDocumentIds()
                                    .then(() => {
                                        this.eutrFiles.forEach(file => {
                                            if(this.Shipment__certification == 'pefc') {
                                                if(!this.fscDocumentIds.includes(file.Document.doctype_id)){
                                                    this.filteredEutrFiles.push(file)
                                                }
                                            } else if(this.Shipment__certification == 'fsc'){
                                                if(!this.pefcDocumentIds.includes(file.Document.doctype_id)){
                                                    this.filteredEutrFiles.push(file)
                                                }
                                            } else {
                                                const certificationFiles = [...this.fscDocumentIds, ...this.pefcDocumentIds]
                                                if(!certificationFiles.includes(file.Document.doctype_id)) {
                                                    this.filteredEutrFiles.push(file)
                                                }
                                            }
                                        })
                                    })
                                    .catch(() => {
                                        console.log('Could not filter the PEFC/FSC files')
                                    })
                            }

                            this.getBuyerContacts().then(() => {
                                this.buyerDocumentsContacts.forEach(c => {
                                    this.selectedBuyerContacts.push(c.Contact.email)
                                    this.selectedEutrBuyerContacts.push(c.Contact.email)
                                })
                            })

                            let contract = this.allActiveContracts.find(contract=>contract.Contract.id == val)
                            if(contract) {
                                this.Contract__title = contract.Contract.title
                                this.Contract__partial_no = contract.Contract.partial_no
                                this.Contract__revision_no = contract.Contract.revision_no
                                this.Contract__version = contract.Contract.version
                                this.Invoice__id = contract.Invoice.id
                                this.Invoice__invno = contract.Invoice.invno
                                this.Customer__title = contract.Customer.title
                                this.Supplier__title = contract.Supplier.title
                                this.title = 'SHPMNT ' + (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
                            } else {
                                let contract = this.allClosedContracts.find(contract=>contract.Contract.id == val)
                                if(contract){
                                    this.Contract__title = contract.Contract.title
                                    this.Contract__partial_no = contract.Contract.partial_no
                                    this.Contract__revision_no = contract.Contract.revision_no
                                    this.Contract__version = contract.Contract.version
                                    this.Invoice__id = contract.Invoice.id
                                    this.Invoice__invno = contract.Invoice.invno
                                    this.Customer__title = contract.Customer.title
                                    this.Supplier__title = contract.Supplier.title
                                    this.title = 'SHPMNT ' + (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
                                } else {
                                    //add an API call to guarantee contract is not null
                                    api
                                        .get('/contracts/' + this.shipmentContractId)
                                        .then(response => {
                                            let contract = response.data.data[0]
                                            this.Contract__title = contract.Contract.title
                                            this.Contract__partial_no = contract.Contract.partial_no
                                            this.Contract__revision_no = contract.Contract.revision_no
                                            this.Contract__version = contract.Contract.version
                                            this.Invoice__id = contract.Contract.Invoice.id
                                            this.Invoice__invno = contract.Contract.Invoice.invno
                                            this.Customer__title = contract.Contract.Customer.title
                                            this.Supplier__title = contract.Contract.Supplier.title
                                            this.title = 'SHPMNT ' + (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
                                        })
                                        .catch(() => {
                                            router
                                                    .push({name: 'not_found_main', params: {resource: 'shipment'}})
                                                    .catch(() => {
                                                        console.log('error')
                                                    })
                                            // this.$toast.error(this.$t('message.errors.relatedContractNotLoaded'),
                                            //     {
                                            //         classes: ['icon-float-left'],
                                            //         icon: 'error_outline'
                                            //     }
                                            // )
                                        })
                                }
                            }
                            this.$title = "Shipment: " + (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
                        })
                        .then(()=>{
                            this.getShippingDocumentsById(val)
                                .then((documents)=>{
                                    this.Shipment__documents = documents
                                    this.buyerDocuments = []
                                    documents.forEach((item) => {
                                        // if(item.AssetDocument.customeraccess == 1){
                                            this.buyerDocuments.push(item)
                                        // }
                                    })

                                    const contractId = this.Shipment__contract_id
                                    api
                                        .get("/tms-documents/shipping/" + contractId,{
                                            params: {
                                                conditions: [
                                                    {
                                                        field: 'documents.customeraccess',
                                                        value: '1'
                                                    }
                                                ]
                                            }
                                        })
                                        .then(response => {
                                            this.tmsShippingDocuments = response.data.data
                                        })
                                        .catch(error => {});
                                    this.getShipmentTracking(val).then(()=>{
                                        this.updatedShipmentUnsaved = false
                                        this.loader = false;
                                    })
                                })
                                .catch(() => {
                                    this.updatedShipmentUnsaved = false
                                    this.loader = false;
                                })
                        })
                        .then( () => {
                            let office = 'hq'
                            if(this.Contract__salestype_id == 5){
                                if(this.Shipment__destinatione_id == 4) office = 'id'
                                if(this.Shipment__destinatione_id == 2) office = 'th'
                                if(this.Shipment__destinatione_id == 1) office = 'my'
                            } else {
                                office = 'hq'
                            }
                            this.getFinanceContacts(office)
                        })
                        .catch(() => {
                            this.updatedShipmentUnsaved = false
                            this.loader = false;
                            router
                                    .push({name: 'not_found_main', params: {resource: 'shipment'}})
                                    .catch(() => {
                                        console.log('error')
                                    })
                        })
            },
            nl2br,
            openSendComplianceFilesDialog() {
                // log(this.buyerDocuments)
                this.sendEutrDocumentsDialog = true
            },
            purify,
            resetInternalRemarkFreeText () {
                this.Shipment__freetext1 = null
                this.Shipment__freetext2 = null
                this.Shipment__freetext3 = null
                this.Shipment__freetext4 = null
            },
            saveShipment () {
                let valid = this.$refs.shipmentForm.validate()
                if (valid) {
                    this.loading.save = true
                    this.updateShipment(this.Shipment__contract_id)
                        .then((status) => {
                            if (status === 'done') {
                                // this.getShippingDocumentsById(this.Shipment__contract_id)
                                //     .then((documents)=>{
                                //         // this.Shipment__documents = documents
                                //         // this.buyerDocuments = []
                                //         // documents.forEach((item) => {
                                //         //     this.buyerDocuments.push(item)
                                //         // })
                                //         // if(this.Shipment__eutr_compliance_required){
                                //         //     this.getFscPefcDocumentIds()
                                //         //         .then(() => {
                                //         //             this.eutrFiles.forEach(file => {
                                //         //                 if(this.Shipment__certification == 'pefc') {
                                //         //                     if(!this.fscDocumentIds.includes(file.Document.doctype_id)){
                                //         //                         this.filteredEutrFiles.push(file)
                                //         //                     }
                                //         //                 } else if(this.Shipment__certification == 'fsc'){
                                //         //                     if(!this.pefcDocumentIds.includes(file.Document.doctype_id)){
                                //         //                         this.filteredEutrFiles.push(file)
                                //         //                     }
                                //         //                 } else {
                                //         //                     const certificationFiles = [...this.fscDocumentIds, ...this.pefcDocumentIds]
                                //         //                     if(!certificationFiles.includes(file.Document.doctype_id)) {
                                //         //                         this.filteredEutrFiles.push(file)
                                //         //                     }
                                //         //                 }
                                //         //             })
                                //         //         })
                                //         //         .catch(() => {
                                //         //             log('Could not filter the PEFC/FSC files')
                                //         //         })
                                //         // }
                                this.$toast.success(this.$t('message.successes.shipmentUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                // this.eurtFileListPageKey += 1
                                // this.fileListPageKey += 1
                                this.loading.save = false
                                this.updatedShipmentUnsaved = false
                                //     })
                                //     .catch((err) => {
                                //         this.$toast.error(err,
                                //             {
                                //                 classes: ['icon-float-left'],
                                //                 icon: 'error_outline'
                                //             }
                                //         )
                                //         this.loading.save = false
                                //     })
                            } else {
                                this.$toast.error(this.$t('message.errors.shipmentNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.loading.save = false
                        })
                        .then(() => {
                            this.loading.save = false
                        })
                }
            },
            async sendCourierInformation() {
                if(this.Shipment__courier == null){
                    this.dialogs.error_message = this.$t('message.errors.pleaseEnterTrackingNumber');
                    this.dialogs.error = true
                } else if(this.Shipment__contract_courier == null) {
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectCourier');
                    this.dialogs.error = true
                } else {
                    if(await this.$root.$confirm(this.$t('message.sendNotification'), this.$t('message.confirmations.continueSendCourierInformationAction'), {color: 'orange'})) {
                        this.loading.save = true
                        this.loading.sendCourier = true
                        this.updateShipment(this.Shipment__contract_id)
                            .then((status) => {
                                if (status == 'done') {
                                    this.loading.save = false
                                    this.updatedShipmentUnsaved = false
                                    let contacts = []
                                    this.buyerDocumentsContacts.forEach(c => {
                                        contacts.push(c.Contact.email)
                                    })
                                    const payload = {
                                        contacts: contacts,
                                        contractId: this.Shipment__contract_id,
                                        courierInfo: {
                                            courier: this.Shipment__contract_courier,
                                            tracking_number: this.Shipment__contract_courier,
                                        }
                                    }
                                    this.sendCourierNotificationToBuyer(payload)
                                        .then((status)=>{
                                            if (status == 'done') {
                                                this.$toast.success(this.$t('message.successes.courierInformationSent'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'check_circle_outline'
                                                    }
                                                )
                                                this.loading.sendCourier = false
                                            } else {
                                                this.$toast.error(this.$t('message.errors.courierInformationNotSent'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                                this.loading.sendCourier = false
                                            }
                                        })
                                        .catch(() => {
                                            this.$toast.error(this.$t('message.errors.courierInformationNotSent'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                            this.loading.sendCourier = false
                                        })
                                } else {
                                    this.$toast.error(this.$t('message.errors.shipmentNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.save = false
                                }
                            })
                            .catch(() => {
                                this.loading.save = false
                            })
                    }
                }
            },
            sendDocumentsToBuyer(){
                if(this.selectedBuyerContacts.length == 0){
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                    this.dialogs.error = true
                } else {
                    if(this.selectedDocuments.length + this.selectedTmsDocuments.length == 0){
                        this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneDocument');
                        this.dialogs.error = true
                    } else {
                        this.loading.send = true
                        let documents = this.Shipment__documents.filter( d => {
                            return this.selectedDocuments.includes( d.AssetDocument.id )
                        })
                        let tmsDocuments = this.tmsShippingDocuments.filter( d => {
                            return this.selectedTmsDocuments.includes( d.Document.id )
                        })
                        let payload = {
                            contacts: this.selectedBuyerContacts,
                            contractId: this.Shipment__contract_id,
                            documents: documents,
                            tmsDocuments: tmsDocuments
                        }
                        this.sendShipmentDocumentsToBuyer (payload)
                            .then((status)=>{
                                if (status == 'done') {
                                    this.$toast.success(this.$t('message.successes.documentsSent'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                } else {
                                    this.$toast.error(this.$t('message.errors.documentsNotSent'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.documentsNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                            .finally(() => {
                                this.loading.send = false
                                this.selectedBuyerContacts = []
                                this.sendDocumentsDialog = false
                            })
                    }
                }
            },
            sendEutrDocumentToBuyer(){
                if(this.selectedEutrBuyerContacts.length == 0){
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                    this.dialogs.error = true
                } else {
                    this.loading.sendEutr = true
                    const eutrFileIds = []
                    this.eutrFiles.forEach(f => {
                        eutrFileIds.push(f.Document.doctype_id)
                    })
                    const documents = this.Shipment__documents.filter( d => {
                        return eutrFileIds.includes( d.AssetDocument.doctype_id )
                    })
                    let payload = {
                        contacts: this.selectedEutrBuyerContacts,
                        contractId: this.Shipment__contract_id,
                        documents: documents
                    }
                    this.sendComplianceDocumentToBuyer(payload)
                        .then((status)=>{
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.documentSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.$toast.error(this.$t('message.errors.documentNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.sendEutr = false
                            this.selectedEutrBuyerContacts = []
                            this.sendEutrDocumentsDialog = false
                        })
                        .catch(() => {
                            this.loading.sendEutr = false
                            this.$toast.error(this.$t('message.errors.documentNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            sendNotificationToFinance () {
                if(this.selectedFinanceContacts.length == 0){
                    this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                    this.dialogs.error = true
                } else {
                    this.loading.sendFinance = true
                    let payload = {
                        contacts: this.selectedFinanceContacts,
                        contractId: this.Shipment__contract_id,
                    }
                    this.sendDocumentNotificationToFinance (payload)
                        .then((status)=>{
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.notificationSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            } else {
                                this.$toast.error(this.$t('message.errors.notificationNotSent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.notificationNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.sendFinance = false
                            this.selectedFinanceContacts = []
                            this.sendNotificationFinanceDialog = false
                        })
                }
            },
            updateContainerRowHandle(idx,field,value) {
                let payload = {
                    index: idx,
                    field: field,
                    value: value,
                }
                this.$store.commit('shipment/updateContainers', payload)
            },
            updateHistoryKey () {
                this.historyKey = Math.random() * (1000 - 1) + 1
            }
        },
        watch: {
            Shipment__agent_id (val) {
                let agent = this.allAgents.find(agent=>agent.Agent.id === val)
                if(agent?.Agent?.scac) {
                    this.Shipment__scac = agent.Agent.scac
                }
            },
            Shipment__internalremark_id(val) {
                if (val == null) {
                    this.Shipment__internalremark_id = null;
                    this.resetInternalRemarkFreeText();
                }
            },
            Shipment__shipmentstatus_id(val) {
                if(val == 6) this.Shipment__etsdate = null
            },
            Shipment__shipmentstatus_text(val) {
                if(this.Shipment__shipmentstatus_id == 1){
                    let week = val
                    let weekNo = val
                    if(week.indexOf('/') !== -1) week = week.split('/')
                    if(week.indexOf(',') !== -1) week = week.split(',')
                    if(week.indexOf('-') !== -1) week = week.split('-')
                    if(week.indexOf(':') !== -1) week = week.split(':')
                    if(Array.isArray(week)) weekNo = week[week.length - 1]
                    if(weekNo != '' || weekNo != null){

                        //calculate the last day of the week number
                        // weekNo = parseInt(weekNo)
                        // let etsDate = getLastDateOfWeek(weekNo)

                        //removed 2021-11-18
                        //if(etsDate && etsDate.length > 0) this.Shipment__etsdate = etsDate
                    }
                }
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.shipment.current.Shipment
                },
                () => {
                    this.updatedShipmentUnsaved = true
                },
                { deep: true }
            )
            // this.$title = "Shipment: " + (this.Contract__version != null ? [this.Contract__title, this.Contract__revision_no , this.Contract__partial_no].filter(Boolean).join('-') : this.Contract__title)
        },
        created(){
            window.addEventListener("beforeunload", this.beforeUnloadHandler)

            // Resets
            // this.$store.dispatch('resetConstantsState')
            // this.$store.dispatch('agent/resetAllAgents')
            // this.$store.commit('contract/resetState')
            // this.getAllActiveContracts()
            // this.resetAllDocuments()
            // this.resetAllContracts()
            // this.allStandardShippingDocuments = []
            this.getAllStandardShippingDocuments()
            if(this.allDocuments.length == 0) this.getAllStandardShippingDocuments()
            if(this.allActiveContracts.length == 0) this.getAllActiveContracts()
            if(this.allClosedContracts.length == 0) this.getAllClosedContracts()
            if(this.allAgents.length == 0) this.getAllAgents()
            // if(this.allStandardShippingDocuments.length == 0) this.getAllStandardShippingDocuments()
            if(this.allCouriers.length == 0) this.getAllCouriers()
            if(this.allInternalRemarks.length == 0) this.getAllInternalRemarks()
            if(this.$route.matched.some(({name}) => name === 'update_shipment')){
                this.loadShipmentById(this.shipmentContractId)
                this.getContainersByShipment(this.shipmentContractId)
            }
        }
    }
</script>

<style>
    .force-text-left {
        text-align: left !important;
    }
</style>